import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  TemplateRef,
} from '@angular/core';

import { TooltipComponent } from './tooltip.component';
import { Overlay } from '../overlay/overlay';

@Directive({ standalone: true, selector: '[tooltip]' })
export class TooltipDirective implements OnDestroy {
  constructor(
    private elementRef: ElementRef<HTMLElement>,
    private overlay: Overlay,
  ) {}

  tooltip = input.required<string | TemplateRef<unknown> | undefined>();

  private componentRef?: ComponentRef<TooltipComponent>;

  ngOnDestroy() {
    this.componentRef?.destroy();
    this.componentRef = undefined;
  }

  @HostListener('pointerenter') private onEnter(): void {
    if (this.componentRef) {
      return;
    }
    const tooltip = this.tooltip();

    if (!tooltip) {
      return;
    }

    const element = this.elementRef.nativeElement;

    this.componentRef = this.overlay.attachComponent(
      TooltipComponent,
      [
        { key: 'tooltip', value: tooltip },
        { key: 'show', value: true },
      ],
      {
        detachStrategies: [
          { type: 'outside-move', relativeTo: this.elementRef.nativeElement },
          { type: 'outside-click' },
          { type: 'scroll' },
        ],
        position: {
          relativeTo: element,
          y: 'top-outer',
          x: 'left-inner',
        },
      },
    );
    this.componentRef?.onDestroy(() => (this.componentRef = undefined));
  }
}
