import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, Renderer2 } from '@angular/core';

import { Icon } from './icon';

@Component({
  selector: 'smw-icon',
  template: ``,
  styleUrls: ['./icon.component.less'],
  imports: [],
})
export class IconComponent implements OnChanges {
  @Input() icon!: Icon;

  private get path(): string {
    return `assets/icons/${this.icon}.svg`;
  }

  constructor(
    private http: HttpClient,
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngOnChanges(): void {
    this.http
      .get(this.path, { responseType: 'text', headers: { cacheable: 'true' } })
      .subscribe((svg) => {
        this.applySVG(svg);
      });
  }

  private applySVG(element: string | SVGElement): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerHTML', element);
  }
}
