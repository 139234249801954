import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input, TemplateRef } from '@angular/core';

@Component({
  selector: 'smw-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.less'],
  imports: [NgTemplateOutlet],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  tooltip = input.required<string | TemplateRef<unknown>>();
  show = input(false);
  disabled = input(false);

  color = '#0E153F';

  position = input('top');

  isEnabled = computed(() => !this.disabled());

  templateText = computed(() => {
    const tooltip = this.tooltip();
    if (tooltip instanceof TemplateRef) {
      return tooltip;
    }

    return undefined;
  });
  text = computed(() => (!this.templateText() ? this.tooltip() : ''));
}
