import { Injectable, signal } from '@angular/core';
import { AnalyticsBrowser } from '@segment/analytics-next';

import { JobResponsibility } from '@smw/common-models';
import { AppConfigService } from '@smw/utils-front';

import { Analytics, AnalyticsUserProfile } from './analytics';
import { AnalyticsEvent } from './events';

@Injectable({ providedIn: 'root' })
export class SegmentAnalytics implements Analytics {
  private segment!: AnalyticsBrowser;
  private userProfile?: AnalyticsUserProfile;

  private isLoaded = signal(false);

  constructor(private configService: AppConfigService) {
    const config = this.configService.getConfig();
    if (!config) {
      return;
    }

    this.segment = AnalyticsBrowser.load(
      {
        writeKey: config.segmentWriteKey,
        cdnURL: config.segmentCdnUrl,
      },
      {
        integrations: {
          'Segment.io': {
            apiHost: config.segmentApiHost,
            protocol: 'https',
          },
        },
      },
    );

    this.isLoaded.set(true);
  }

  identify(userId: string, profile: AnalyticsUserProfile): void {
    if (!this.isLoaded()) {
      console.error('Segment not loaded, cannot identify user', { userId, profile });
      return;
    }

    this.userProfile = profile;
    let jobResponsibilities = '';

    switch (profile.jobResponsabilities) {
      case JobResponsibility.COACH:
        jobResponsibilities = 'persona_4';
        break;
      case JobResponsibility.DIRECTION:
        jobResponsibilities = 'persona_2';
        break;
      case JobResponsibility.OTHER:
        jobResponsibilities = 'persona_5';
        break;
      case JobResponsibility.RH:
        jobResponsibilities = 'persona_1';
        break;
      default:
        jobResponsibilities = '';
        break;
    }

    this.segment.identify(userId, {
      email: profile.email,
      firstName: profile.firstname,
      lastName: profile.lastname,
      company: {
        name: profile.tenant,
      },
      jobResponsibilities,
      businessService: profile.businessService,
      newsletterConsent: !!profile.newsletterConsent,
    });

    if (profile.tenant) {
      this.segment.group(profile.tenant);
    }
  }

  track(event: AnalyticsEvent): void {
    if (!this.isLoaded()) {
      console.error('Segment not loaded, cannot track event', event);
      return;
    }

    this.segment.track(event.id, event.data ?? {}, {
      traits: {
        email: this.userProfile?.email,
        company: {
          name: this.userProfile?.tenant,
        },
      },
    });
  }

  disconnectIdentity(): void {
    // Not required
  }

  startTimer(): void {
    // Not required
  }
}
