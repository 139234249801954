import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'smw-textfield',
  imports: [],
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextFieldComponent,
      multi: true,
    },
  ],
})
export class TextFieldComponent implements ControlValueAccessor {
  label = input('');
  placeholder = input('');
  type = input<'text' | 'email' | 'number'>('text');
  invalid = input(false);
  specialStyle = input<'ShareConversation' | undefined>(undefined);

  @ViewChild('input', { static: true, read: ElementRef })
  inputField!: ElementRef<HTMLInputElement>;

  inputId?: string;

  onChange: (value: string) => void = () => {};
  onTouched!: () => void;
  onValidationChange!: () => void;

  constructor(
    @Attribute('id') public id: string,
    private element: ElementRef,
    private renderer: Renderer2,
  ) {
    if (this.id) {
      this.inputId = this.id;
      this.renderer.removeAttribute(this.element.nativeElement, 'id');
    }
  }

  writeValue(value: string | undefined): void {
    this.inputField.nativeElement.value = value ?? '';
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidationChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.inputField) {
      this.renderer.setProperty(this.inputField.nativeElement, 'disabled', isDisabled);
    }
  }
}
