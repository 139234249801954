<div class="email-input">
  <div #emailListing class="tag-renderer">
    @for (email of this.emails(); track $index) {
      <div>
        {{ email }}
        <div (click)="removeEmail($index)">
          <smw-icon [icon]="closeIcon" />
        </div>
      </div>
    }
  </div>
  <input
    #emailInput
    type="text"
    formControl="email"
    [placeholder]="effectivePlaceholder()"
    (keydown)="onKeyDown($event)"
  />
</div>
