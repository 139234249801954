@if (showSuccessModal()) {
  <smw-success
    [title]="'auth_activation_confirmation_title' | translate: { userFirstName: userFirstName() }"
    [message]="'auth_activation_confirmation_description' | translate"
    [ctaLabel]="'auth_activation_confirmation_description' | translate"
    (cta)="redirectToHome()"
  ></smw-success>
} @else {
  <smw-auth-template>
    <div>
      <smw-icon class="logo" [icon]="logoIcon" />

      <h1 class="title-md mb-3">{{ 'auth_activation_title' | translate }}</h1>
      <p class="body-lg mb-8">
        <span class="body-md">{{ 'auth_register_account-exists' | translate }}</span>
        <a class="link-md login-link" [routerLink]="['auth.login' | route]">
          {{ 'auth_register_login' | translate }}
        </a>
      </p>

      <form class="form" [formGroup]="form">
        <div class="flex w-full gap-3">
          <smw-textfield
            #firstNameInput
            type="text"
            class="mb-6 p-0 border-0"
            style="font-family: Nexa; font-weight: 300"
            formControlName="firstname"
            [placeholder]="'input-firstname-placeholder' | translate"
          />

          <smw-textfield
            #lastNameInput
            type="text"
            class="mb-6 p-0 border-0"
            style="font-family: Nexa; font-weight: 300"
            formControlName="lastname"
            [placeholder]="'input-lastname-placeholder' | translate"
          />
        </div>

        <div class="password-group mb-6">
          <smw-password
            formControlName="password"
            [placeholder]="'input-password-placeholder' | translate"
            [invalid]="hasFormRequestError()"
          />
          @if (form.get('password')?.hasError('pattern')) {
            <p class="caption-sm text-secondary-red-500">
              {{ 'error-input-pattern-password' | translate }}
            </p>
          }
        </div>

        <div class="checkbox rgpd">
          <input type="checkbox" id="rgpd" name="input" formControlName="rgpd" />
          <label
            for="rgpd"
            class="body-sm"
            [innerHTML]="'auth_register_rgpd-agreement' | translate"
          ></label>
        </div>

        <div class="checkbox newsletter">
          <input
            type="checkbox"
            id="newsletterConsent"
            name="input"
            formControlName="newsletterConsent"
          />
          <label
            for="newsletterConsent"
            class="body-sm"
            [innerHTML]="'auth_register_newsletter-agreement' | translate"
          ></label>
        </div>
      </form>
    </div>

    @if (formRequestErrorMessage()) {
      <p class="caption-sm text-secondary-red-500">
        {{ formRequestErrorMessage() | translate }}
      </p>
    }

    <button
      smw-button
      kind="filled"
      class="confirm"
      [disabled]="isConfirmDisabled()"
      (click)="submit()"
    >
      {{ 'auth_activation_action' | translate }}
    </button>
  </smw-auth-template>
}
