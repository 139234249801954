import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  enableProdMode,
  ErrorHandler,
  importProvidersFrom,
  inject,
  provideAppInitializer,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  Router,
  TitleStrategy,
  withComponentInputBinding,
  withInMemoryScrolling,
} from '@angular/router';
import * as Sentry from '@sentry/angular';
import { HotkeyModule } from 'angular2-hotkeys';

import { AnalyticsModule } from '@smw/analytics-front';
import { ApiClientModule } from '@smw/api-client-front';
import { authInterceptor } from '@smw/authentication-front';
import { AngularTranslateModule } from '@smw/translate-front';
import { iconCacheInterceptor } from '@smw/ui-front';
import { AngularCommonUtilsModule, AppConfigService } from '@smw/utils-front';

import packageJson from '../package.json';
import { AppComponent } from './app/app.component';
import { routes } from './app/routes';
import { PageTitleStrategy } from './app/title.strategy';
import { environment } from './environments/environment';

const { version } = packageJson;
if (environment.production) {
  enableProdMode();
}

const envName = environment.production ? 'production' : 'dev';

Sentry.init({
  dsn: 'https://e23c0a9dbeeb497186a812fa2e6c3743@o1177722.ingest.sentry.io/4505330183962624',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      networkRequestHeaders: ['Cache-Control'],
      networkResponseHeaders: ['Referrer-Policy'],
      // We don't have time to customize the masking setting for now, so we revert to showing everything
      // Except input to avoid blatant pricacy violations
      maskAllText: false,
      blockAllMedia: false,
      maskAllInputs: true,
    }),
    // Sentry.feedbackIntegration({
    //   // Additional SDK configuration goes in here, for example:
    //   colorScheme: 'system',
    // }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Note: would be better to have a real "envName" env variable defined so we can distingish sentry reports between staging/preprod/dev etc
  tracePropagationTargets: [
    'localhost',
    'somanyways.co',
    'workoscope.co',
    /^https:\/\/*.somanyways\.co/,
    /^https:\/\/*.workoscope\.co/,
  ],
  environment: envName,
  release: version,
  beforeSend(event) {
    if (event.user) {
      // For security purpose, we remove the password from the user object
      delete event.user['password'];
    }

    return event;
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ub = (<any>window).Userback;
if (ub) {
  //docs.userback.io/docs/attach-custom-data
  ub.custom_data = {
    app_version: version,
  };
}

bootstrapApplication(AppComponent, {
  providers: [
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor, iconCacheInterceptor])),
    provideRouter(
      routes,
      withComponentInputBinding(),
      withInMemoryScrolling({ scrollPositionRestoration: 'top' }),
    ),
    // { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
    importProvidersFrom(
      HotkeyModule.forRoot(),
      AngularTranslateModule,
      AnalyticsModule,
      ApiClientModule,
      AngularCommonUtilsModule,
    ),
    // The import above seem to use ErrorModule from traxion libs but these doesnt seem to be used nor working. Maybe it relies on env vars being defined
    // But for better control I am fallbacking to a custom implementation without traxion
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        // There are currently to many unhanled exception, we can't rely on this as user will get spam on most pages
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() =>
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ((service) => {
        /**
         * We must have something here otherwise linter complains for empty function.
         * Not sure what are the implication of removing the empty function for the factory here.
         * Also we'd like to keep this linting restriction, this case is just required by Sentry
         */
      })(inject(Sentry.TraceService)),
    ),
    provideAppInitializer(() => ((config) => config.initialize())(inject(AppConfigService))),
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
  ],
}).catch(console.error);
